import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: "G-TVSCG40CG9",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCeyI__Vcnb1s1qok7O5nV3llje1IvpCE0",
  authDomain: "hurricane-bbf63.firebaseapp.com",
  projectId: "hurricane-bbf63",
  storageBucket: "hurricane-bbf63.appspot.com",
  messagingSenderId: "1068407400321",
  appId: "1:1068407400321:web:e89fb656a9a88857f1aa80",
  measurementId: "G-TVSCG40CG9"
};

if (
  !firebaseConfig.apiKey ||
  !firebaseConfig.authDomain ||
  !firebaseConfig.projectId ||
  !firebaseConfig.storageBucket ||
  !firebaseConfig.messagingSenderId ||
  !firebaseConfig.appId
) {
  console.error("Missing Firebase environment variables");
}

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
